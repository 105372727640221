import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FormRepuestos = () => {
    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState(0)
    const [ marcas, setMarcas ] = useState([]);
    const [ marca, setMarca ] = useState(0)
    const [ ubicaciones, setUbicaciones ] = useState([]);
    const [ ubicacion, setUbicacion ] = useState(0)
    const [ encontrado, setEncontrado ] = useState(0)
    
    const { idRepuesto, repuestoSelected, setIdRepuesto, setOpenAlta, idUsuario } = useContext(ContextEstados);
    

    let tipoImagen = "repuestos";
    let idHtml = "imagenesRepuesto";
    const childRef = useRef()

    useEffect(()=>{
        const consultaUnidades = async () => {
            const url = Url+'api/lUnidades.php';
            const resultado = await axios.get(url);
            
            setUnidades(resultado.data.results);
            setUnidad(resultado.data.results[0].id);
        }
        consultaUnidades()
    },[])


    useEffect(()=>{
        const consultaMarcas = async () => {
            const url = Url+'api/listMarcas.php';
            const resultado = await axios.get(url);
            console.log(resultado)
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id);
        }
        consultaMarcas()
    },[])

    useEffect(()=>{
        const consultaUbicaciones = async () => {
            const url = Url+'api/lUbicaciones.php';
            const resultado = await axios.get(url);
            
            setUbicaciones(resultado.data.results);
            setUbicacion(resultado.data.results[0].id);
        }
        consultaUbicaciones()
    },[])

    useEffect(()=>{
        if (idRepuesto!==0)
        {
            childRef.current.handleSubmit();
        }
    },[idRepuesto])

    const checkRepuesto = async (codigo) => {
        const formData=new FormData()
            formData.append('codigo',codigo);
            try{
              const response = await axios({
                url: Url+'api/checkRepuesto.php',
                method: 'POST',
                data: formData,
                })
                if (response.data.results.id!==0)
                {
                    setEncontrado(1)
                    formik.setFieldValue('descripcion',response.data.results.descripcion);
                    formik.setFieldValue('costo',response.data.results.costo);
                    formik.setFieldValue('stock',response.data.results.stock);
                    formik.setFieldValue('stockMinimo',response.data.results.stockMinimo);
                    setUnidad(response.data.results.unidad);
                    setMarca(response.data.results.marca);
                }
                else
                {
                    if (encontrado!==0)
                    {
                        setEncontrado(0)
                        formik.setFieldValue('descripcion','');
                        formik.setFieldValue('costo','');
                        formik.setFieldValue('stock','');
                        formik.setFieldValue('stockMinimo','');
                        setUnidad(unidades[0].id);
                        setMarca(marcas[0].id);
                    }
                }
                
            } catch (e) {
              //console.log(e)
            }
    }

    const formik = useFormik({
        initialValues:{
            codigo: '',
            descripcion: '',
            costo: '',
            stock: '',
            stockMinimo: ''
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                    .required("El código es necesario"),
            descripcion: Yup.string()
                    .required("Debe ingresar una descripción"),
            costo: Yup.number()
                    .required("Debe ingresar un costo"),
            stock: Yup.number()
                    .required("Debe ingresar un stock"),
            stockMinimo: Yup.number()
                    .required("Debe ingresar un stock mínimo"),

        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('codigo',valores.codigo);
            formData.append('descripcion',valores.descripcion);
            formData.append('costo',valores.costo);
            formData.append('stock',valores.stock);
            formData.append('stockMinimo',valores.stockMinimo);
            formData.append('unidad',unidad);
            formData.append('marca',marca);
            formData.append('encontrado',encontrado);
            formData.append('idusuario',idUsuario);
            formData.append('ubicacion',ubicacion);
            try{
                const response = await axios({
                    url: Url+'api/repuestosAlta.php',
                    method: 'POST',
                    data: formData,
                })
            setIdRepuesto(response.data.results.idrepuesto);
            resetForm({ values: ''});
        } catch (e) {
            console.log(e)
        }

        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Producto</h2>
            <div className="sm:items-start">
                <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase font-bold">Código</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="codigo" 
                    placeholder="Ingrese un código" 
                    type="text" 
                    value={formik.values.codigo}
                    onChange={(e)=> { 
                        formik.handleChange(e); 
                        checkRepuesto(e.target.value)
                    }}
                    onKeyUp={(e)=>checkRepuesto(e.target.value)}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.codigo && formik.errors.codigo ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.codigo}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Descripción de Producto" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="costo" className="block text-gray-600 uppercase font-bold">Costo</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="costo" 
                    placeholder="Costo de Producto" 
                    type="text" 
                    value={formik.values.costo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.costo && formik.errors.costo ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.costo}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sUnidades" className="block text-gray-600 uppercase font-bold">Unidad de medida</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sUnidades' 
                    onChange={(e)=>setUnidad(e.target.value)}
                    value={unidad}
                >
                    { typeof unidades !== "undefined" && (
                        unidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    )}
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sMarcas" className="block text-gray-600 uppercase font-bold">Marca</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sMarcas'
                    onChange={(e)=>setMarca(e.target.value)}
                    value={marca}
                >
                    { typeof marcas !== "undefined" && (
                        marcas.map(item => (
                            <option key={item.id} value={item.id}>{item.descripcion}</option>))
                    )}
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sUbicaciones" className="block text-gray-600 uppercase font-bold">Ubicaciones</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sUbicaciones' 
                    onChange={(e)=>setUbicacion(e.target.value)}
                    value={ubicacion}
                >
                    { typeof ubicaciones !== "undefined" && (
                        ubicaciones.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    )}
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="stock" className="block text-gray-600 uppercase font-bold">Stock</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="stock" 
                    placeholder="Stock a ingresar" 
                    type="number" 
                    value={formik.values.stock}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.stock && formik.errors.stock ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.stock}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="stockMinimo" className="block text-gray-600 uppercase font-bold">Stock Mínimo</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="stockMinimo" 
                    placeholder="Stock mínimo" 
                    type="number" 
                    value={formik.values.stockMinimo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.stockMinimo && formik.errors.stockMinimo ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.stockMinimo}</p>
                    </div>
                ) : null }
            </div>
        </div>
        <div className="pb-10">
            <SubirImagenes 
                id={idRepuesto} 
                tipo={tipoImagen} 
                ref={childRef}
                idHtml={idHtml}
            />
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value={ encontrado===0 ? "Guardar Datos" : "Actualizar Cantidades"}
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenAlta(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FormRepuestos