import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewUsuario = () => {
    //const { equipo } = useContext(ContextEquipos);
    const { setOpenVista, usuarioSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Apellido: </span>{usuarioSelected.apellido}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nombre: </span>{usuarioSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Email: </span>{usuarioSelected.mail}</p>
            <p className={`${usuarioSelected.habilitado==='1' ? 'bg-green-300': 'bg-red-300'} ' p-2 rounded-md text-xl text-center mt-4 mb-8 '`}>{usuarioSelected.habilitado==='1' ? "Habilitado":"No habilitado"}</p>
            <div className='w-1/2 mx-auto'>
                { usuarioSelected.imagen && (
                <img className='w-full object-cover rounded-xl' src={`${Url}${usuarioSelected.imagen}`} />
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewUsuario