import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';

const ListadoRepuestosSinStock = () => {
    const [repuestosSinStock, setRepuestosSinStock] = useState([])
    const { idRepuesto, setRepuestoSelected, buscarRepuesto, setTipoModal, verStock, setOpenVista, idUsuario, sucursales } = useContext(ContextEstados);

    useEffect(() => {
        const consultaRepuestos = async () => {
            const formData = new FormData()
            formData.append('verStock', 1)
            formData.append('busqueda', buscarRepuesto)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/repuestosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setRepuestosSinStock(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaRepuestos()
    }, [idRepuesto, buscarRepuesto, verStock, sucursales])

    const asignarRepuesto = (id) => {
        repuestosSinStock.forEach(element => {
            if (element.id === id) {
                setRepuestoSelected({
                    id: element.id,
                    codigo: element.codigo,
                    descripcion: element.descripcion,
                    costo: element.costo,
                    nombreunidad: element.nombreunidad,
                    idunidad: element.idunidad,
                    nombremarca: element.nombremarca,
                    idmarca: element.idmarca,
                    imagen: element.imagen
                })
            }
        });
    }

    const verRepuesto = async (id) => {
        asignarRepuesto(id);
        setTipoModal(7)
        setOpenVista(true);
    }
    const editarRepuesto = async (id) => {
        asignarRepuesto(id);
        setTipoModal(8)
        setOpenVista(true);
    }
    const eliminarRepuesto = async (id) => {
        asignarRepuesto(id);
        setTipoModal(9)
        setOpenVista(true);
    }


    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-gray-300'>
                        <th className='border-gray-400 p-2'>Código</th>
                        <th className='border-gray-400 p-2'>Descripción</th>
                        <th className='border-gray-400 p-2'>Costo</th>
                        <th className='border-gray-400 p-2'>Marca</th>
                        <th className='border-gray-400 p-2'>Unidad</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof repuestosSinStock !== 'undefined' ? (
                            repuestosSinStock.map((repuesto) => (

                                <tr
                                    key={repuesto.id}
                                    className={('bg-white hover:bg-slate-100 border-b border-gray-200')}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{repuesto.codigo}</td>
                                    <td className='border-gray-400 p-1 text-center'>{repuesto.descripcion}</td>
                                    <td className='border-gray-400 p-1 text-center'>${repuesto.costo}</td>
                                    <td className='border-gray-400 p-1 text-center'>{repuesto.nombremarca}</td>
                                    <td className='border-gray-400 p-1 text-center'>{repuesto.nombreunidad}</td>
                                    <td className='p-1 flex justify-center'>
                                        <button
                                            className='bg-indigo-500 p-3 rounded-md text-white'
                                            onClick={() => verRepuesto(repuesto.id)}
                                        >
                                            <FaEye />
                                        </button>
                                        <button
                                            className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => editarRepuesto(repuesto.id)}
                                        >
                                            <FaPen />
                                        </button>
                                        <button
                                            className='bg-red-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => eliminarRepuesto(repuesto.id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoRepuestosSinStock