import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthState from './context/auth/authState'
import Index from './paginas/Index'
import Ubicaciones from './paginas/Ubicaciones'
import Repuestos from './paginas/Repuestos'
import AdministracionUsuarios from './paginas/AdministracionUsuarios'
import ContextEstados from './context/Estados'
import ModalAlta from './components/ModalAlta'
import ModalVista from './components/ModalVista'
import ModalImgVista from './components/ModalImgVista'
import Reporting from './paginas/Reporting'
import Perfil from './paginas/Perfil'
import BackNotifications from './components/BackNotifications'
import CreateNotification from './components/CreateNotification'



const App = () => {
	const [itemSidebar, setItemSidebar] = useState(0);
	const [roles, setRoles] = useState([]);
	const [tipoUsrLogin, setTipoUsrLogin] = useState(0);
	const [sucursales, setSucursales] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
	const [repuestos, setRepuestos] = useState([]);
	const [buscarUsuario, setBuscarUsuario] = useState('');
	const [buscarRepuesto, setBuscarRepuesto] = useState('');
	const [ubicacionSelected, setUbicacionSelected] = useState({});
	const [idUbicacion, setIdUbicacion] = useState(0);
	const [buscarUbicacion, setBuscarUbicacion] = useState('');
	const [verStock, setVerStock] = useState(0);
	const [formAlta, setFormAlta] = useState(0);
	const [idUsuario, setIdUsuario] = useState(0);
	const [usuarioSelected, setUsuarioSelected] = useState({});
	const [tipoUsuario, setTipoUsuario] = useState(0);
	const [repuestoSelected, setRepuestoSelected] = useState({});
	const [idUsuarioNuevo, setIdUsuarioNuevo] = useState(0);
	const [idRepuesto, setIdRepuesto] = useState(0);
	const [openAlta, setOpenAlta] = useState(false);
	const [openVista, setOpenVista] = useState(false);
	const [tipoModal, setTipoModal] = useState(0);
	const [listadoRoles, setListadoRoles] = useState([]);
	const [mensaje, setMensaje] = useState("");
	const [notification, setNotification] = useState("");
	const [messageOut, setMessageOut] = useState("");
	const [openModalImg, setOpenModalImg] = useState(false);
	const [formImgView, setFormImgView] = useState(0);
	const [imageSelected, setImageSelected] = useState('');
	const [tokens, setTokens] = useState([]);


	return (

		<AuthState>
			<ContextEstados.Provider
				value={{
					itemSidebar, setItemSidebar,
					roles, setRoles,
					tipoUsrLogin, setTipoUsrLogin,
					idUsuario, setIdUsuario,
					sucursales, setSucursales,
					usuarios, setUsuarios,
					buscarUsuario, setBuscarUsuario,
					repuestos, setRepuestos,
					buscarRepuesto, setBuscarRepuesto,
					ubicacionSelected, setUbicacionSelected,
					buscarUbicacion, setBuscarUbicacion,
					idUbicacion, setIdUbicacion,
					verStock, setVerStock,
					usuarioSelected, setUsuarioSelected,
					tipoUsuario, setTipoUsuario,
					repuestoSelected, setRepuestoSelected,
					formAlta, setFormAlta,
					openAlta, setOpenAlta,
					openVista, setOpenVista,
					idUsuarioNuevo, setIdUsuarioNuevo,
					idRepuesto, setIdRepuesto,
					tipoModal, setTipoModal,
					listadoRoles, setListadoRoles,
					mensaje, setMensaje,
					notification, setNotification,
					messageOut, setMessageOut,
					openModalImg, setOpenModalImg,
					formImgView, setFormImgView,
					imageSelected, setImageSelected,
					tokens, setTokens
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<Index />} />
						<Route path='/ubicaciones' element={<Ubicaciones />} />
						<Route path='/repuestos' element={<Repuestos />} />
						<Route path='/reporting' element={<Reporting />} />
						<Route path='/administracionUsuarios' element={<AdministracionUsuarios />} />
						<Route path='/perfil' element={<Perfil />} />
					</Routes>
					<ModalAlta />
					<ModalVista />
					<ModalImgVista />
					
					<CreateNotification />
				</BrowserRouter>
			</ContextEstados.Provider>
		</AuthState>
	)
}

export default App
