import React, { useReducer } from "react";
import authContext from "./authContext";
import authReducer from "./authReducer";
import axios from "axios";

import { Url } from '../../components/Url';

import { 
    LIMPIAR_ALERTA,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION

} from "../../types";


const AuthState = ({children}) => {

    const intitialState = {
        token: localStorage.getItem('sst_tk'),
        autenticado: null,
        usuario: null,
        procesando: false,
        mensaje: null,
        nivel: null,
        modLogin: null,
        modRegistro: null
    }

    //Definir el reducer
    const [ state, dispatch ] = useReducer(authReducer, intitialState);

    //Iniciar sesion

    const iniciarSesion = async datos => {
        const formData=new FormData()
        formData.append('mail',datos.mail);
        formData.append('password',datos.password);

        const procesando=true;
        try{
            const response = await axios({
                url: Url+'api/iniciarsesion.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            dispatch({
                type:LOGIN_EXITOSO,
                payload: response.data.results
            })
            procesando=false;

        } catch (e) {
            dispatch({
                type:LOGIN_ERROR,
                payload: "Email y/o Contraseña incorrecta",
                procesando:false
            })
        }
        setTimeout(() => { 
            dispatch ({
                type:LIMPIAR_ALERTA
            })
        }, 3000)

    }


    // Retorno usuario autenticado JWT

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('sst_tk');
        const webApiUrl = Url+'api/tokenAuth.php';

        try {
            const response = await axios.get(webApiUrl, { headers: { Authorization: `Bearer ${token}`} });
            //console.log("auth:"+response)
            dispatch({
                type:USUARIO_AUTENTICADO,
                payload: response.data.results.usuario
            })
            
        } catch (error) {
            console.log(error)
        }
    }


    const cerrarSesion = async () => {
        const token = localStorage.getItem('sst_tk');
        const webApiUrl = Url+'api/cerrarsesion.php';

        try {
            const response = await axios.get(webApiUrl, { headers: { Authorization: `Bearer ${token}`} });
            dispatch({
                type:CERRAR_SESION
            })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <authContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                nivel: state.nivel,
                mensaje: state.mensaje,
                procesando: state.procesando,
                iniciarSesion,
                usuarioAutenticado,
                cerrarSesion
            }}
        >
            {children}
        </authContext.Provider>
    )
}

export default AuthState;
