import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';
import TablaUsuarios from './tables/TablaUsuarios';

const ListadoUsuarios = ({tipoUsuarios}) => {
    const { idUsuarioNuevo, 
        buscarUsuario,
        usuarios,
        setUsuarios, 
        idUsuario,
        sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaUsuarios = async () => {
            const formData=new FormData()
            formData.append('tipo',tipoUsuarios)
            formData.append('busqueda',buscarUsuario)
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/usuariosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setUsuarios(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaUsuarios()
    },[idUsuarioNuevo, buscarUsuario,sucursales])


    return (
    <div>
        {typeof usuarios !== 'undefined' && (<TablaUsuarios usuarios={usuarios}/>)}
    </div>
    )
}

export default ListadoUsuarios