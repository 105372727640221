import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChartGraph = props => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                { props.graph===1 ? (
                    <>
                <Bar dataKey="Iniciadas" fill="#FF8042" />
                <Bar dataKey="Activas" fill="#FFBB28" />
                <Bar dataKey="Cerradas" fill="#00C49F" />
                <Bar dataKey="Totales" fill="#0088FE" />
                    </>
                ):  props.graph===2 ? (
                    <>
                <Bar dataKey="Falla Mecánica" fill="#FF8042" />
                <Bar dataKey="Falla Eléctrica" fill="#FFBB28" />
                <Bar dataKey="Falla de Fabricación" fill="#00C49F" />
                <Bar dataKey="No se determina" fill="#0088FE" />
                    </>
                ) :  props.graph===3 ? (
                    <>
                <Bar dataKey="Solicitudes" fill="#413ea0" />
                    </>
                ) :  props.graph===4 ? (
                    <>
                <Bar dataKey="Fallas" fill="#ff7300" />
                    </>
                ) : (
                    <>
                <Bar dataKey="Cantidad" fill="#413ea0" />
                    </>
                )}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartGraph