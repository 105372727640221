import { Fragment, useRef, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'


import FViewUbicacion from './FViewUbicacion'
import FEditUbicacion from './FEditUbicacion'
import FDeleteUbicacion from './FDeleteUbicacion'
import FViewUsuario from './FViewUsuario'
import FEditUsuario from './FEditUsuario'
import FDeleteUsuario from './FDeleteUsuario'
import FViewRepuesto from './FViewRepuesto'
import FEditRepuesto from './FEditRepuesto'
import FDeleteRepuesto from './FDeleteRepuesto'
import FormChSucursal from './FormChSucursal'
import ContextEstados from '../context/Estados'

const ModalVista = () => {
	const { tipoModal, openVista, setOpenVista } = useContext(ContextEstados);


	const cancelButtonRef = useRef(null)

	return (
		<>
			<Transition.Root show={openVista} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenVista}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:w-1/2 w-1/2">
									<div className="px-4 pt-2 sm:p-6">
										<h3 className='text-2xl text-gray-600 text-center uppercase'>{
											tipoModal >= 1 && tipoModal <= 3 ? ('Equipo') :
											tipoModal >= 4 && tipoModal <= 6 ? ('Usuario') :
											tipoModal >= 6 && tipoModal <= 9 ? ('Producto') :
											tipoModal >= 10 && tipoModal <= 13 ? ('Variable de Control') :
											tipoModal >= 14 && tipoModal <= 17 ? ('Actividades') :
											tipoModal >= 18 && tipoModal <= 20 ? ('Plan de Mantenimiento') :
											tipoModal >= 25 && tipoModal <= 27 ? ('Equipos - Actividadaes') : ('')}</h3>
										{tipoModal === 4 ? (
											<FViewUsuario />
										) : tipoModal === 5 ? (
											<FEditUsuario />
										) : tipoModal === 6 ? (
											<FDeleteUsuario />
										) : tipoModal === 7 ? (
											<FViewRepuesto />
										) : tipoModal === 8 ? (
											<FEditRepuesto />
										) : tipoModal === 9 ? (
											<FDeleteRepuesto />
										) : null}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

		</>
	)
}

export default ModalVista