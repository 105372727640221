import {
    LIMPIAR_ALERTA,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION,
 } from "../../types";


export default (state, action) => {
    switch(action.type) {

        case LOGIN_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        case LOGIN_EXITOSO:
            localStorage.setItem('sst_tk',action.payload.token)
            localStorage.setItem('sstpp_m',action.payload.mail)
            localStorage.setItem('sst_t',action.payload.tipo)
            return {
                ...state,
                token: action.payload,
                autenticado: true,
                procesando: false
            }
        
        case LIMPIAR_ALERTA:
            return {
                ...state,
                mensaje: null
            }
        case USUARIO_AUTENTICADO:
            return {
                ...state,
                usuario:action.payload
            }

        case CERRAR_SESION:
            localStorage.removeItem('sst_tk');
            localStorage.removeItem('sstpp_m');
            localStorage.removeItem('sst_t');
            return {
                ...state,
                usuario: null,
                token: null,
                autenticado: null,
                procesando: false,
                nivel: null
            }
        default: 
            return state;
    }
}