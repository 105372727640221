import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListadoRepuestos from '../components/ListadoRepuestos';
import ListadoRepuestosSinStock from '../components/ListadoRepuestosSinStock';
import { RiFileExcel2Line } from 'react-icons/ri'


const Repuestos = () => {
  
	const { setOpenAlta, setFormAlta, buscarRepuesto, verStock, setVerStock, setBuscarRepuesto } = useContext(ContextEstados);

	const Alta = () => {
		setFormAlta(3);
		setOpenAlta(true)
	}

	const Import = () => {
		setFormAlta(11);
		setOpenAlta(true);
	}
	
	return (
		<Layout 
		pagina="Productos"
		>
			<div className="w-full flex-1 bg-slate-500">
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Productos</h1>
				<div className='px-10 py-1 bg-black/5'>
					<button
						className={`${verStock===0 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
						onClick={()=>setVerStock(0)}
					>Todos</button>
					<button
						className={`${verStock===1 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
						onClick={()=>setVerStock(1)}
					>Stock bajo</button>
				</div>
				{
					verStock===0 ? (
				<div className='w-full mt-2 bg-white'>
					<div className='w-full h-16 py-2 px-10 bg-slate-400 flex'>             
					<button 
						className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
						onClick={() => Alta(true)}
					>
						<AiOutlinePlus className='text-2xl mr-2'/>
						Agregar Producto
					</button>
					<button 
						className='bg-green-600 hover:bg-green-400 shadow-lg ml-5 p-3 rounded-md text-white uppercase flex'
						onClick={() => Import(true)}
					>
						<p className='text-xl mr-2'>
							<RiFileExcel2Line/>
						</p>
						Importar
					</button>
					<div className='py-3 rounded-3xl bg-white w-1/3 mx-auto px-3 flex'>
						<input
							type="text"
							className='w-11/12 outline-none'
							value={buscarRepuesto}
							placeholder="Buscar Producto"
							onChange={(e)=>setBuscarRepuesto(e.target.value)}
						/>
						<AiOutlineSearch className='ml-auto text-2xl'/>
					</div>
					</div>
					<div className='w-full h-screen p-10 bg-slate-200 overflow-scroll'>
						<ListadoRepuestos/>
					</div>
				</div>  
				) : (
				<div className='w-full mt-2 bg-white'>
					<div className='w-full h-full p-10 bg-slate-200'>
					<ListadoRepuestosSinStock/>
					</div>
				</div> 
				)
				}
			</div>
		</Layout>
	)
}

export default Repuestos