import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const ListadoUbicaciones = () => {
    const [ubicaciones, setUbicaciones] = useState([])
    const { idUbicacion,
        ubicacionSelected,
        setUbicacionSelected,
        setTipoModal,
        setOpenVista,
        roles,
        buscarUbicacion, idUsuario, sucursales } = useContext(ContextEstados);

    useEffect(() => {
        const consultaUbicaciones = async () => {
            const formData = new FormData()
            formData.append('busqueda', buscarUbicacion)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/ubicacionesList.php',
                    method: 'POST',
                    data: formData,
                })
                setUbicaciones(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaUbicaciones()
    }, [idUbicacion, buscarUbicacion, sucursales])

    const asignarUbicacion = (id) => {
        ubicaciones.forEach(element => {
            if (element.id === id) {
                setUbicacionSelected({
                    id: element.id,
                    nombre: element.nombre,
                    descripcion: element.descripcion,
                    idarea: element.idarea
                })
                return
            }
        });
    }

    const verUbicacion = async (id) => {
        asignarUbicacion(id);
        setTipoModal(40)
        setOpenVista(true);
    }
    const editarUbicacion = async (id) => {
        asignarUbicacion(id);
        setTipoModal(41)
        setOpenVista(true);
    }
    const eliminarUbicacion = async (id) => {
        asignarUbicacion(id);
        setTipoModal(42)
        setOpenVista(true);
    }


    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Nombre</th>
                        <th className='border-gray-400 p-2'>Descripción</th>
                        <th className='border-gray-400 p-2'>Área</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof ubicaciones !== 'undefined' ? (
                            ubicaciones.map((ubicacion, index) => (

                                <tr
                                    key={ubicacion.id}
                                    className={`hover:cursor-pointer border-b border-gray-200  ${ubicacionSelected.id === ubicacion.id ? 'bg-blue-200' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}
                                    onClick={() => asignarUbicacion(ubicacion.id)}
                                >
                                    <td className='border-gray-400 p-2 text-center'>{ubicacion.nombre}</td>
                                    <td className='border-gray-400 p-2 text-center'>{ubicacion.descripcion}</td>
                                    <td className='border-gray-400 p-2 text-center'>{ubicacion.area}</td>
                                    <td className='p-1 flex justify-center'>
                                        <button
                                            className='bg-indigo-500 p-3 rounded-md text-white'
                                            onClick={() => verUbicacion(ubicacion.id)}
                                        >
                                            <FaEye />
                                        </button>
                                        {(roles.includes("1") || roles.includes("11")) && (
                                            <>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => editarUbicacion(ubicacion.id)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => eliminarUbicacion(ubicacion.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoUbicaciones