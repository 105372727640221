import { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const CreateNotification = () => {
    const { idUsuario, messageOut } = useContext(ContextEstados);
    useEffect(()=>{
        const crearNotificacion = async() => {
            if (messageOut!=="")
            {
                const formData=new FormData();
                formData.append('message',messageOut);
                formData.append('idusuario',idUsuario);
                try{
                    const response = await axios({
                        url: Url+'api/crearNotificacion.php',
                        method: 'POST',
                        data: formData,
                    })
                } 
                catch (e) {
                    console.log(e)
                }
            }
        }
        crearNotificacion()
    },[messageOut])
    return(
        <>
        </>
    )
}

export default CreateNotification;