import { useContext, useEffect } from 'react'
import authContext from '../context/auth/authContext';

const AuthAlert = () => {
    const AuthContext = useContext(authContext);
    const { mensaje } = AuthContext;

    return (
        <>
        { mensaje!=="" && (
        <div className='absolute w-1/2 mx-auto '>
            <div className='bg-orange-300 uppercase text-bold text-center text-white p-3 rounded-xl z-50'>
                {mensaje}
            </div>
        </div>
        )}
        </>
    );
}
 
export default AuthAlert;