import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from '../components/Url';
import BarChartGraph from '../components/charts/BarChartGraph';
import PieChartGraph from '../components/charts/PieChartGraph';

import Datepicker from "react-tailwindcss-datepicker";

const Reporting = () => {
	const [ ots, setOts ] = useState([])
	const [ otsPorcentajes, setOtsPorcentajes ] = useState([])
	const [ solicitudes, setSolicitudes ] = useState([])
	const [ solFechas, setSolFechas ] = useState([])
	const [ fallas, setFallas ] = useState([])
	const [ plVsSol, setPlVsSol ] = useState([])
	const [ reqRepuestos, setReqRepuestos ] = useState([])
	const { idUsuario, roles } = useContext(ContextEstados);
	const [ dateValue, setDateValue ] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    
	const mail = localStorage.getItem('sstpp_m');
	const navigate = useNavigate();
    
	const handleValueChange = (newValue) => {
        //console.log("newValue:", newValue);
        setDateValue(newValue);
    }

	useEffect (()=>{
		if (!roles.includes("1") && !roles.includes("40")) {
		navigate("/", { replace: true });
		}
	},[mail])

    useEffect(()=>{
		//setDateValue({startDate:'2022-12-01',endDate:'2023-03-04'})
		//console.log(dateValue.startDate)

		const cantidadesOt = async () => {
			const formData=new FormData()
			formData.append('idusuario',idUsuario);
			formData.append('startDate',dateValue.startDate);
			formData.append('endDate',dateValue.endDate);
			try{
				const resultado = await axios({
					url: Url+'api/reportes.php',
					method: 'POST',
					data: formData,
				})
				console.log(resultado)
				setOts(resultado.data.results.ots)
				setOtsPorcentajes(resultado.data.results.otsPorcentajes)
				setSolicitudes(resultado.data.results.solicitudes)
				setPlVsSol(resultado.data.results.plVsSol)
				setSolFechas(resultado.data.results.solFechas)
				setFallas(resultado.data.results.equiposFallas)
				setReqRepuestos(resultado.data.results.reqRepuestos)
				//console.log(resultado.data.results.sql)
			} catch (e) {
				console.log(e)
			}
		}
		cantidadesOt()
	},[idUsuario,dateValue])

	return (
		<Layout 
		pagina="Reportes"
		>
			<div className="w-full flex-1 bg-slate-500">
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Reportes</h1>
				<div className='w-full h-full bg-white'>
					<div className="flex w-full md:w-1/3 mx-auto items-center justify-center p-5">
						<Datepicker
							i18n={"es"}
							primaryColor={"fuchsia"} 
							value={dateValue}
							onChange={handleValueChange}
						/>
					</div>
					<div className='w-full mt-2 md:flex'>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Estado de Órdenes de Trabajo</h4>
								{ typeof ots!== 'undefined' &&
								(
									<BarChartGraph data={ots} graph={1}/>
								)}
							</div>
						</div>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Estado de Órdenes de Trabajo (Porcentajes)</h4>
								<PieChartGraph data={otsPorcentajes}/>
							</div>
						</div>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Planificación Vs. Solicitudes</h4>
								<PieChartGraph data={plVsSol}/>
							</div>
						</div>
					</div>
					<div className='w-full flex'>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Tipos de Falla (Solicitudes)</h4>
								{ typeof solicitudes!== 'undefined' &&
								(
									<BarChartGraph data={solicitudes} graph={2}/>
								)}
							</div>
						</div>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Solicitudes de Trabajo diarias</h4>
								{ typeof ots!== 'undefined' &&
								(
									<BarChartGraph data={solFechas} graph={3}/>
								)}
							</div>
						</div>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Equipos con mas fallas</h4>
								{ typeof ots!== 'undefined' &&
								(
									<BarChartGraph data={fallas} graph={4}/>
								)}
							</div>
						</div>
					</div>
					<div className='w-full flex'>
						<div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Repuestos mas usados</h4>
								{ typeof ots!== 'undefined' &&
								(
									<BarChartGraph data={reqRepuestos} graph={5}/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>       
		</Layout>
	)
}

export default Reporting