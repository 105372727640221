import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ContextEstados from '../context/Estados';

const SideLink = ({children, label, id, link}) => {
  const { itemSidebar, setItemSidebar } = useContext(ContextEstados);
  return (
    <li className="relative">
        <Link className={ id===itemSidebar? "flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden bg-gray-800 text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" :
        "flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out"} 
            id={id}
            to={link}
            onClick={()=>setItemSidebar(id)}
        >
          <span className='text-xl'>
            {children}

          </span>
            <p className='ml-2'>{label}</p>
        </Link>
    </li>
  )
}

export default SideLink