import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import Layout from '../components/Layout';
import axios from "axios";
import ContextEstados from '../context/Estados';
import { Url } from '../components/Url';

const Index = () => {
	const [ otPendL, setOtPendL ] = useState(0)
	const [ otPendM, setOtPendM ] = useState(0)
	const [ otPendH, setOtPendH ] = useState(0)
	const [ otCerrL, setOtCerrL ] = useState(0)
	const [ otCerrM, setOtCerrM ] = useState(0)
	const [ otCerrH, setOtCerrH ] = useState(0)
	const [ atrasadas, setAtrasadas ] = useState(0)
	const [ proximas, setProximas ] = useState(0)
	const [ cantidadEquipos, setCantidadEquipos ] = useState(0)
	const [ cantidadRepuestos, setCantidadRepuestos ] = useState(0)
	const [ alertaStock, setAlertaStock ] = useState(0)
	const [ otIniciadas, setOtIniciadas ] = useState(0)
	const [ otAbiertas, setOtAbiertas ] = useState(0)
	const [ otCerradas, setOtCerradas ] = useState(0)
	const [ otCanceladas, setOtCanceladas ] = useState(0)
	const { idUsuario,roles,tipoUsrLogin, setVerStock, setEstadoOrden, sucursales } = useContext(ContextEstados)

const navigate = useNavigate();


	useEffect(()=>{
		const cantidadesOt = async () => {
		const formData=new FormData()
		formData.append('idusuario',idUsuario);
		try{
			const resultado = await axios({
				url: Url+'api/cantidadesInicio.php',
				method: 'POST',
				data: formData,
			})
		setOtPendL(resultado.data.results.pendientesL)
		setOtPendM(resultado.data.results.pendientesM)
		setOtPendH(resultado.data.results.pendientesH)
		setOtCerrL(resultado.data.results.cerradasL)
		setOtCerrM(resultado.data.results.cerradasM)
		setOtCerrH(resultado.data.results.cerradasH)
		setAtrasadas(resultado.data.results.atrasadas)
		setProximas(resultado.data.results.proximas)
		setCantidadEquipos(resultado.data.results.cantidadequipos)
		setCantidadRepuestos(resultado.data.results.cantidadrepuestos)
		setAlertaStock(resultado.data.results.alertastock)
		setOtIniciadas(resultado.data.results.otiniciadas)
		setOtAbiertas(resultado.data.results.otabiertas)
		setOtCerradas(resultado.data.results.otcerradas)
		setOtCanceladas(resultado.data.results.otcanceladas)
		} catch (e) {
			console.log(e)
		}
		}
		cantidadesOt()

	},[idUsuario])

	const verOrdenesPendientes = () => {
		setEstadoOrden(1)
		navigate("/ordenes", { replace: true });
	}

	const verOrdenesCerradas = () => {
		setEstadoOrden(2)
		navigate("/ordenes", { replace: true });
	}
	
	const verRepuestos = () => {
		setVerStock(0)
		navigate("/repuestos", { replace: true });
	}
	const controlStock = () => {
		setVerStock(1)
		navigate("/repuestos", { replace: true });
	}


	return (
		<Layout
			pagina="Inicio"
		>
			<div className="w-full flex-1 bg-slate-500">
				{(tipoUsrLogin!=='2' && tipoUsrLogin!=='4') && (					
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Administración del Sistema</h1>
				)}
				<div className="w-full bg-white p-5 md:flex-1 min-h-screen">
					{(tipoUsrLogin!=='2') ? (
					<>
					<div className="w-full bg-white md:flex">
						<div 
							className="w-full md:w-1/3 p-5 md:mt-5 md:mx-5 bg-slate-200 hover:bg-slate-300 shadow-md hover:shadow-xl hover:cursor-pointer rounded-xl"
							onClick={()=>verRepuestos()}
						>
							<h3 className="text-center mt-3 text-gray-600 text-2xl uppercase">Productos</h3>
							<p className="text-gray-600 text-lg text-center">{cantidadRepuestos}</p>
						</div>
						<div 
							className="w-full md:w-1/3 p-5 mt-5 md:mx-5 bg-slate-200 hover:bg-slate-300 shadow-md hover:shadow-xl hover:cursor-pointer rounded-xl"
							onClick={()=>controlStock()}
						>
							<h3 className="text-center mt-5 text-gray-600 text-2xl uppercase">Productos con stock bajo</h3>
							<p className="text-gray-600 text-lg text-center">{ alertaStock }</p>
						</div>				
					</div>
					</> ):( null
					)}
				</div>
			</div>
		</Layout>
	)
}

export default Index